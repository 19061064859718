import "./PageNotFound.css"

const PageNotFound = () => {
    return (
        <>
            <h2>404: Page Not Found</h2>
            <h3>Please use the home button to return to the home page</h3>
        </>
    )
}

export default PageNotFound;
